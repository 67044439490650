import * as TYPES from '../../store/types'

const state = {
  selectedTabIndex: '0',

  workers: [],
  workers_metadata: {},

  workcentergroups: [],
  workcentergroups_metadata: {},

  sites: [],
  sites_metadata: {},

  readOnly: false,
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.RESOURCES_SET_DATA](st, obj) {

    if (obj.selectedTabIndex) st.selectedTabIndex = obj.selectedTabIndex;
    
    if (obj.workers) st.workers = obj.workers;
    if (obj.workers_metadata) st.workers_metadata = obj.workers_metadata;

    if (obj.workcentergroups) st.workcentergroups = obj.workcentergroups;
    if (obj.workcentergroups_metadata) st.workcentergroups_metadata = obj.workcentergroups_metadata;

    if (obj.sites) st.sites = obj.sites;
    if (obj.sites_metadata) st.sites_metadata = obj.sites_metadata;

    st.readOnly = !!obj.readOnly
  },
}

const actions = {
  resourcesSetData({ commit }, obj) {
    commit(TYPES.RESOURCES_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
