import axios from 'axios'
import interceptors from './interceptors'
import { apiUrl } from '../../config'

// allow use http client without Vue instance
export const http = axios.create({
  baseURL: apiUrl,
})

/**
 * Helper method to set the header with the token
 */
export function setToken(token) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`
}

export function setSite(siteId) {
  http.defaults.headers.common['X-Site-Id'] = `${siteId}`
}

export function getToken() {
  return http.defaults.headers.common.Authorization
}

export function getBaseUrl() {
  return http.defaults.baseURL
}

// receive app instance and options
export default {
  install: (app, { store, router }) => {
    interceptors(http, store, router)
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
    app.config.globalProperties.$http = http
  }
}