import { createApp } from 'vue'
import locale from 'element-plus/dist/locale/en.mjs'
import VueCookies from 'vue3-cookies'
import { mapGetters, mapState, mapActions } from 'vuex'
import Root from './Root'
import Helpers from './utils/helpers'
import Localization from './utils/localization'

import Validation from './utils/validation'
import CcVarList from './components/varlist/main'
import CcVarControl from './components/varcontrol/main'
import CcInputArray from './components/varcontrol/inputarray'
import CcTotals from './components/totals/main'
import CcFilesList from './components/fileslist/main'
import CcFileCtrl from './components/filectrl/main'
import CcSearch from './components/search/main'
import CcCustomActionButton from './components/customactionbutton/main'
import CcOrderDeliveryHandover from './components/handoverrecord/orderdeliveryhandover'
import CcInlineVarList from './components/inlinevarlist/main'
import CcTreeVarList from './components/treevarlist/main'
import CcVarGroup from './components/vargroup/main'
import { apiUrl } from './config'
import CcTitle from './components/general/title'
import CcActions from './components/general/actions'
import VueSweetalert2 from 'vue-sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css';
import CcTimeline from './components/reports/timeline'
import CcLineChart from './components/reports/linechart'
import CcBarChart from './components/reports/barchart'
import CcDdp from './components/reports/ddp'
import { http, setToken } from './plugins/http'
import httpPlugin, { getToken } from './plugins/http'

// import buffer from 'buffer';

import CcOrderProgressesCarousel from './components/shopfloor/orderprogressescarousel'
import CcOrdersExecutionOverview from './app/reports/ordersexecutionoverview'
import CcProjectProgress from './app/reports/projectprogress'

import ganttastic from '@infectoone/vue-ganttastic'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// This is the Vuex store and it is avaible to all your components
import store from './store'

// This is the Router
import router from './router'

// eventbus plugin
import eventbus from './plugins/eventbus'

import signalrclient from './plugins/signalrclient'


// jQuery and Bootstrap includes
require('./includes')

const app = createApp(Root)

app.config.devtools = process.env.NODE_ENV == 'development'
//app.config.performance = true

// Register the store and router plugins with the app
app.use(store)
app.use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// app.use(ElementPlus);
app.use(ElementPlus, { locale })

// Make $http avaible to all components Send store and router to httpPlugin (injection)
app.use(httpPlugin, { store, router })

app.use(signalrclient, `${apiUrl}/v1/ws`)

// Make $bus avaible to all components
app.use(eventbus)
// app.config.globalProperties.$bus = eventbus;

app.use(VueSweetalert2)

app.use(VueCookies)
// app.config.globalProperties.$cookies = VueCookies

app.use(ganttastic)

app.component('cc-var-list', CcVarList)
app.component('cc-var-control', CcVarControl)
app.component('cc-input-array', CcInputArray)
app.component('cc-totals', CcTotals)
app.component('cc-inline-var-list', CcInlineVarList)
app.component('cc-tree-var-list', CcTreeVarList)
app.component('cc-files-list', CcFilesList)
app.component('cc-file-ctrl', CcFileCtrl)
app.component('cc-search', CcSearch)
app.component('cc-custom-action-button', CcCustomActionButton)
app.component('cc-order-delivery-handover', CcOrderDeliveryHandover)
app.component('cc-var-group', CcVarGroup)
app.component('cc-timeline', CcTimeline)
app.component('cc-linechart', CcLineChart)
app.component('cc-barchart', CcBarChart)
app.component('cc-ddp', CcDdp)
app.component('cc-title', CcTitle)
app.component('cc-actions', CcActions)
app.component('cc-order-progresses-carousel', CcOrderProgressesCarousel)
app.component('cc-orders-execution-overview', CcOrdersExecutionOverview)
app.component('cc-project-progress', CcProjectProgress)


/* eslint-disable */
app.mixin({
  calculated: {

  },

  beforeMount() {
    if (this.$store) {
      this.setAppSkin()
    }
  },

  data() {
    return {
      currentLanguage: 'EN',
    }
  },

  watch: {
    'currentToken': function(newToken) {
      this.$socket.authenticate(newToken)
    },
  },

  computed: {
    ...mapGetters([
      'globalMetadata',
      'currentUser',
      'isShopFloorWorker',
      'currentToken',
      'mainMenu',
    ]),

    language: function () {
      return this.currentLanguage
    },

    appSkin: function () {
      var appSkin = this.globalMetadata ? this.globalMetadata.appSkin : null
      if (!appSkin) {
        appSkin = this.$cookies.get('appSkin')
      }

      return appSkin
    },

    getMainMenuItems(){
      return this.isLogged 
        ? this.mainMenu 
        : [ {
          id: "auth.signin",
          text: "Login",
          class: "menu-item-right",
        }]
    },

    getDefaultPage() {
      return this.mainMenu 
        ? this.mainMenu.find(n => n.isDefault)
        : {}
    },

  },

  methods: {
    setAppSkin() {
      var appSkin = this.appSkin
      if (appSkin && appSkin.styles) {
        for (var item in appSkin.styles) {
          if (appSkin.styles.hasOwnProperty(item)) {
            // const name = item.startsWith('__') ? `--${item.substr(2)}` : item
            const name = item
            // const value = `${appSkin.styles[item]}!important`
            const value = appSkin.styles[item]
            document.body.style.setProperty(name, value)
          }
        }
      }
    },

    setLanguage(lang) {
      this.$cookies.set('currentlanguage', lang, 30)
      this.currentLanguage = lang
    },

    restoreCurrentLanguage() {
      this.currentLanguage = this.$cookies.get('currentlanguage')
      if (!this.currentLanguage) {
        return http
          .get('https://api.country.is')
          .then((res) => {
            this.setLanguage(res.data.country)
          })
          .catch(() => {})
      }
    },

    translateFormat(formatStr, args, lang) {
      var result = this.translate(formatStr, lang)
      if (!result || !args || args.length == 0)
        return result

      var replacements = args.split(',')
      for(var i = 0; i < replacements.length; i++) {
        result = result.replace('{' + i + '}', replacements[i])
      }

      return result
    },

    translate(str, lang) {
      if (!str)
        return str

      var result = this.translateUsingMetadata(str)
      
      if (result)
        return result

      if (!lang)
        lang = this.language

      return Localization.translate(str, lang)
    },

    translateUsingMetadata(str) {
      var result =
        this.globalMetadata && this.globalMetadata.translations
          ? this.globalMetadata.translations[str]
          : null
      
      if (!result) { 
        var s = str.charAt(0).toLowerCase() + str.slice(1)
        result =
        this.globalMetadata && this.globalMetadata.translations
          ? this.globalMetadata.translations[s]
          : null
      }

      
      return result
    },

    getISODateUsingTimezone(date){
      const timezoneOffset = date.getTimezoneOffset() * 60000;
      const correctedDate = new Date(date.getTime() - timezoneOffset);
      return correctedDate.toISOString();
    },

    getFormattedDate(src) {
      if (this.globalMetadata && this.globalMetadata.dateFormat && src) {
        var d = new Date(src)
        src = this.date2str(d, this.globalMetadata.dateFormat)
      }

      return src
    },

    getFormattedTimeSpan(minutes) { 
      if (!minutes)
        return `0${this.translate('h')}`
      
      var hours = Math.floor(minutes / 60)
      var mins = Math.floor(minutes) % 60
      return mins > 0 
        ? `${hours}${this.translate('h')} ${mins}${this.translate('m')}`
        : `${hours}${this.translate('h')}`
    },

    date2str(x, y) {
      var z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      }
      y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2)
      })

      return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length)
      })
    },

    getDurationString(startTime, endTime) {
      const duration = endTime - startTime;

      return this.getDurationStringFromMinutes(duration / (60 * 1000))
    },

    getDurationStringFromMinutes(totalMinutes) {
      const days = Math.floor(totalMinutes / (24 * 60))
      const hours = Math.floor((totalMinutes % (24 * 60 )) / 60)
      const minutes = Math.floor((totalMinutes % 60))

      const parts = []
      if (days > 0) {
        parts.push(`${days}d`)
      }
      if (hours > 0) {
        parts.push(`${hours}h`)
      }
      if (minutes > 0) {
        parts.push(`${minutes}m`)
      }

      return parts.join(' ')
    },

    getHoursDurationStringFromMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60)
      const minutes = Math.floor(totalMinutes % 60)

      const parts = []
      if (hours > 0) {
        parts.push(`${hours}h`)
      }
      if (minutes > 0) {
        parts.push(`${minutes}m`)
      }

      return parts.join(' ')
    },

    findHierObjectAndPropertyName: function (entity, path) {
      return Helpers.findHierObjectAndPropertyName(entity, path)
    },
    openFileDocument: function (id, dontShowPrint) {
      Helpers.openFileDocument(id, dontShowPrint)
    },
    openFileDocumentNew: function (contentType, name, contents) {
      return Helpers.openFileDocumentNew(contentType, name, contents)
    },
    deleteFileDocument: function (id) {
      return Helpers.deleteFileDocument(id)
    },
    navigateToLink(path) {
      if(path == 'invalid-link')
        return;

      if (path.startsWith('http')) {
        window.open(path, '_blank');
      } else if(path.startsWith('/')){
        window.open(path, '_blank');
      } else {
        window.open('/'+ path, '_blank');
      }
    },

    /*uploadFile: function(formData, containerType, containerId, propName) {
      return Helpers.uploadFile(formData, containerType, containerId, propName)
    },*/
    decodeBase64: function (str) {
      return Helpers.decodeBase64(str)
    },
    getValidationRules() {
      return Validation.getRules()
    },

    getFormValidationRules(metadata, model) {
      return Validation.getFormRules(metadata, model, this.translate)
    },

    getValidationPropName(cellMetadata, componentIdx) {
      return Validation.getValidationPropertyName(cellMetadata, componentIdx)
    },

    isValid(func) {
      return this.$refs['form'].validate((valid) => {
        if(valid) {
          func()
        }
      })
    },

    getObjectActions() {
      if (!this.metadata || !this.metadata.actions) {
        return []
      } else {
        return this.metadata.actions
      }
    },

    getWithoutParentProperties(itm) {
      const replacer = function (key, value) {
        if (key === 'parent') {
          return undefined
        }
        return value
      };

      return JSON.parse(JSON.stringify(itm, replacer));
    },

    getTitle(sectionName, entity) {
      var result = ''
      if (this.metadata) {
        if (!sectionName) {
          result = this.metadata.title || ''
        } else if (this.metadata[sectionName]) {
          result = this.metadata[sectionName].title || ''
        }
      }

      result = this.translate(result)

      if (this.$route) {
        if (this.$route.name.endsWith('.new')) {
          result += ` - ${this.globalMetadata.translations['New']}`
        } else if (this.$route.name.endsWith('.edit')) {
          result += ` - ${this.globalMetadata.translations['Edit']}`
          if (sectionName === 'generalInfo' && entity) {
            if (entity.externalRefId) {
              result += ` (${entity.externalRefId})`
            } else {
              result += ` (${entity.id})`
            }
          }
        }
      }

      return result
    },

    showInfo(msg) {
      this.$notify.info({
        position: 'bottom-right',
        title: 'Info',
        message: msg,
      })
    },

    showApiSuccess(data) {
      var message = null
      if(data.message) {
        if(data.message.format) {
          message = this.translateFormat(data.message.format, data.message.args) 
        } else {
          message = this.translate(data.message)
        }
      }

      if(message) {
        return this.$notify({
          position: 'bottom-right' /*, title: 'Success'*/,
          message: message,
          type: 'success',
        })
      }
    },

    /* not in use anymore - use showApiSuccess
    showSuccess(msg) {
      return this.$notify({
        position: 'bottom-right',
        message: msg,
        type: 'success',
      })
    },*/

    showWarning(msg) {
      this.$notify({
        position: 'bottom-right',
        title: 'Warning',
        message: msg,
        type: 'warning',
      })
    },

    showError(msg) {
      this.$notify({
        position: 'bottom-right',
        title: 'Error',
        message: msg,
        type: 'error',
      })
    },

    showHtmlInfo(msg) {
      this.$notify({
        position: 'bottom-right',
        title: 'Info',
        message: msg,
        dangerouslyUseHTMLString: true,
        type: 'info',
      })
    },

    showHtmlError(msg) {
      this.$notify({
        position: 'bottom-right',
        title: 'Error',
        message: msg,
        dangerouslyUseHTMLString: true,
        type: 'error',
      })
    },

    showHtmlSuccess(msg) {
      this.$notify({
        position: 'bottom-right',
        title: 'Success',
        message: msg,
        dangerouslyUseHTMLString: true,
        type: 'success',
      })
    },

    showApiError(error) {
      if (error.response && error.response.data.error) {
        if(error.response.data.error.fields && error.response.data.error.fields.length > 0) {
          this.$swal(this.translate('Error'), this.translateFormat(error.response.data.error.message, error.response.data.error.fields), 'error')
        } else  {
          this.$swal(this.translate('Error'), this.translate(error.response.data.error.message), 'error')
        }
      } else if(error.response.data) {
        if(error.response.data.indexOf('413')) {
          this.$swal(this.translate('Error'), this.translate('Request Entity Too Large'), 'error')
        } else {
          this.$swal(this.translate('Error'), error.response.data, 'error')
        }
      } else if(error.message) {
        this.$swal(this.translate('Error'), this.translate(error.message), 'error')
      } else {
        this.$swal(this.translate('Error'), 'Unexpected error', 'error')
      }
    },

    confirmExecute(message, action) {
      return this.$swal({
        title: this.translate('Are you sure?'),
        text: this.translate(message),
        icon: 'warning',
        showCancelButton: true,
      }).then((result) => {
        if (result && result.isConfirmed) {
          action()
        }
      })
    },

    // copy as reactive properties, thus they will remain same object
    copyProperties(to, from) {
      Helpers.copyProperties(to, from)
    },

    // copy as values, thus properties will be different objects
    copyValueProperties(to, from) {
      Helpers.copyValueProperties(to, from)
    },

    buildUrl(baseUri, searchOptions, customerId, componentId, orderId) {
      return Helpers.buildUrl(
        baseUri,
        searchOptions,
        customerId,
        componentId,
        orderId
      )
    },

    routerPushEdit(moduleName, obj) {
      if (moduleName && obj && obj.id && obj.id > 0) {
        this.$router.push({ path: `/${moduleName}/${obj.id}` })
      }
    },

    // used for durationUnits atm.
    toShortString(src) {
      switch (src) {
        case "Days":
          return "day"
        case "Hours":
          return "hour"
        case "Minutes":
        default:
          return "min"
      }
    },
    
    toDurationInUserUnits(src) { 
      switch (this.globalMetadata.durationUnits) { 
          case "Days":
            return Math.round((src / (60 * 24)) * 100) / 100
          case "Hours":
            return Math.round((src / 60) * 100) / 100
          case "Minutes":
          default:
            return src
        }
    },

    toUserPricePerDurationUnits(src) { 
      switch (this.globalMetadata.durationUnits) { 
          case "Days":
            return Math.round((src * 24 * 60 * 100) / 100)
          case "Hours":
            return Math.round((src * 60) * 100) / 100
          case "Minutes":
          default:
            return src
        }
    },

    getTotalPriceOfComponentRecursively(component, quantity, useTotalQuantity) {
      const useTotalQuantity1 = useTotalQuantity
      const getChildrenPriceOfComponentRecursively = function(children, parentQuantity) {
        var r = 0
        if(children && children.length > 0) {
            children.forEach(c=> {
                var totalQuantity = useTotalQuantity1 
                  ? c.quantity  // for orders quantity is already total
                  : c.quantity * parentQuantity
                r += c.priceOfComponent * totalQuantity + getChildrenPriceOfComponentRecursively(c.children, totalQuantity)
            })
        }
        
        return r
      }

      return useTotalQuantity1 
        ? component.priceOfComponent * quantity + getChildrenPriceOfComponentRecursively(component.children, 1) // for orders quantity is already total
        : (component.priceOfComponent + getChildrenPriceOfComponentRecursively(component.children, 1)) * quantity
    },   

    setSignalRBindings(bindings) {
      this.$socket.setEventCallback(this.onSignalREvent)
      this.$socket.setBindings(bindings)
    },

    onSignalREvent(eventName, payload) {
      switch(eventName) {
        case 'message':
          console.error('SSE Unknown event message: ', payload)
          break;
        case 'update-order':
          if (this.onOrderUpdated)
            this.onOrderUpdated(payload.order)
          break;
        case 'update-order-component-action':
          if (this.onOrderComponentActionUpdated) 
            this.onOrderComponentActionUpdated(payload.orderComponentAction)
          break;
        case 'update-alert':
          if (this.onAlertUpdated) 
            this.onAlertUpdated(payload.alert)
          break;
        case 'update-notifications':
          if (this.onOrderComponentActionUpdated)
            this.currentUser.notificationsCount = payload.count
          break;
      }
    },
  },

})

// Effortlessly keep vue-router and vuex store in sync.
// sync(store, router) // https://github.com/vuejs/vuex-router-sync/tree/next

// Mount the app to the DOM
app.mount('#app')
