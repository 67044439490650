<template>
  <div class="search">
    <el-input v-if="inputSize=='large'"
      v-model="searchFilter.searchText"
      :placeholder="translate('Search') + ' ...'"
      @input="searchChanged"
      size="large"
      class="large-search"
    />
    <el-input v-else
      v-model="searchFilter.searchText"
      :placeholder="translate('Search') + ' ...'"
      @input="searchChanged"
    />

    <div
      v-if="hasOptions && initDefaultOptions()"
      class="search-panel-item filter-options"
    >
      <el-select
        v-model="searchFilter.options"
        multiple
        placeholder="filter by status"
        @change="selectedOptionsChanged"
      >
        <el-option
          v-for="item in getOptions"
          :key="item.id"
          :label="item.text"
          :value="item.text"
        >
        </el-option>
      </el-select>
    </div>

    <div
      v-if="hasDateRange && initDefaultDateRange()"
      class="search-panel-item"
    >
      <el-date-picker
        :shortcuts="pickerOptions2 && pickerOptions2.shortcuts"
        :disabled-date="pickerOptions2 && pickerOptions2.disabledDate"
        :cell-class-name="pickerOptions2 && pickerOptions2.cellClassName"
        v-model="searchFilter.dateRange"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="To"
        start-placeholder="From"
        end-placeholder="To"
        :format="getDateFormat"
        @change="selectedDateRangeChanged"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CcSearch',

  props: {
    metadata: {},
    initialSearchText: undefined,
    inputSize: undefined,    
  },

  data() {
    return {
      timeout: null,
      searchFilter: { options: [], dateRange: [], searchText: undefined },
      pickerOptions2: {
        shortcuts: [
          {
            text: 'Last week',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            },
          },
          {
            text: 'Last month',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            },
          },
          {
            text: 'Last 3 months',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            },
          },
        ],
      },
    }
  },

  mounted() {
    if(this.initialSearchText)
      this.searchFilter.searchText = this.initialSearchText
  },

  computed: {
    ...mapGetters(['globalMetadata']),

    hasOptions() {
      return (
        this.metadata && this.metadata.options && this.metadata.options.length
      )
    },

    getOptions() {
      return this.hasOptions ? this.metadata.options : []
    },

    hasDateRange() {
      return this.metadata && this.metadata.hasDateRange
    },

    getDefaultDateRange() {
      return this.hasDateRange && this.metadata.dateRange
        ? this.metadata.dateRange
        : null
    },

    getSearchText() {
      return this.searchFilter.searchText &&
        this.searchFilter.searchText.length >= 1
        ? this.searchFilter.searchText
        : undefined
    },

    getFilterOptions() {
      return this.hasOptions ? this.searchFilter.options : []
    },
    
    getDateRange() {
      return this.hasDateRange ? this.searchFilter.dateRange : undefined
    },

    getDateFormat() {
      if (this.globalMetadata && this.globalMetadata.dateFormat) {
        return this.globalMetadata.dateFormat.toUpperCase()
      }

      return 'yyyy-MM-dd'.toUpperCase()
    },
  },

  methods: {
    searchChanged(text) {
      this.onFilterChanged()
    },

    selectedOptionsChanged(val) {
      this.onFilterChanged()
    },

    selectedDateRangeChanged(val) {
      this.onFilterChanged()
    },

    onFilterChanged() {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        /*console.log('--- emit')
                console.log(this.getSearchText)
                console.log(this.getFilterOptions)
                console.log(this.getDateRange)*/

        this.$emit('searchFilterChanged', {
          searchText: this.getSearchText,
          options: this.getFilterOptions,
          dateRange: this.getDateRange,
        })
      }, 2200 * Math.random())
    },

    initDefaultOptions() {
      if (!this.searchFilter.defaultOptionsInitialized) {
        this.getOptions.forEach((o) => {
          if (o.isDefault) {
            this.searchFilter.options.push(o.text)
          }
        })

        this.searchFilter.defaultOptionsInitialized = true
      }

      return true
    },

    initDefaultDateRange() {
      if (!this.searchFilter.defaultDateRangeInitialized) {
        if (this.getDefaultDateRange) {
          this.searchFilter.dateRange[0] = new Date(this.getDefaultDateRange[0])
          this.searchFilter.dateRange[1] = new Date(this.getDefaultDateRange[1])
        }

        this.searchFilter.defaultDateRangeInitialized = true
      }

      return true
    },

    reset() {
      this.searchFilter.searchText = ''
    }
  },
}
</script>

<style scoped>
.search {
  display: flex;
  margin-bottom: 4px;
}
.search-panel-item {
  margin-left: 8px;
}
.filter-options {
  min-width: 560px;
}
.large-search {
  font-size: x-large;
  height: 42px;
}
</style>
